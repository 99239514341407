import { useEffect, useState } from "react";
import { Grid, H3 } from "@maggioli-design-system/react";
import { ILoader } from "../Interfaces/ILoader";
import { ResultSelectedPlan, SubscriptionPlanOne, SubscriptionPlanPremium, SubscriptionPlanPro } from "../components/SubscriptionPlan";
import { ISubInfo } from "./SubscribeStep2";
import { scrollTop } from "../lib/Functions";
import ContactForm from "../components/ContactForm";
import { footerMessageSubMail } from "../lib/Constants";

interface ISubscribe {
    loader: ILoader;
    ip: string;
}

const Subscribe = ({ loader, ip }: ISubscribe): JSX.Element => {
    const [step2, setStep2] = useState<boolean>(false);
    const subInfo: ISubInfo = JSON.parse(sessionStorage.getItem("sub"));

    useEffect(() => {
        document.title = `Abbonati | Biblioteca Digitale`;
    }, []);

    const selectSub = () => {
        scrollTop();
        setStep2(true);
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <Grid className="view-limit lg:px-8" columns="1" gutter="xlarge" rows="fit-vertically">
                <H3 className="text-center">{!step2 ? "Scegli l'abbonamento che fa per te" : "Richiedi un preventivo"}</H3>
                {!step2 ? (
                    <Grid className="view-limit sm:mx-20 mx-3 -mt-5" columns="3">
                        <SubscriptionPlanOne setShow={() => {}} setShow2={selectSub} />
                        <SubscriptionPlanPro setShow={() => {}} setShow2={selectSub} />
                        <SubscriptionPlanPremium setShow={() => {}} setShow2={selectSub} />
                    </Grid>
                ) : (
                    <div className="view-limit md:mx-20 mx-3 -mt-5 md:flex md:gap-10">
                        <div className="md:w-[40rem] w-full mb-10">
                            <ResultSelectedPlan setShow={() => {}} backToFirst={() => setStep2(false)} data={subInfo} />
                        </div>
                        <ContactForm
                            ip={ip}
                            footerMessage={footerMessageSubMail(subInfo)}
                            loader={loader}
                            mailSubject="Biblioteca Digitale - Richiesta preventivo"
                            requestType="sales"
                        />
                    </div>
                )}
            </Grid>
        </div>
    );
};

export default Subscribe;
