import { useEffect } from "react";
import { H2 } from "@maggioli-design-system/react";

interface IErrorPage {
    errorNum: number;
    title: string;
}

const ErrorPage = ({ errorNum, title }: IErrorPage): JSX.Element => {
    useEffect(() => {
        document.title = `Error ${errorNum} | Biblioteca Digitale`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="px-8 background-color-adjust-tone-19">
            <div className="view-limit">
                <H2 className="text-center vertical-padding-large">
                    Error {errorNum} <br />
                    {title}
                </H2>
            </div>
        </div>
    );
};

export default ErrorPage;
