import axios from 'axios';
import * as Constants from '../lib/Constants';

// controlla su approfondimenti se il volume/volumi a cui corrisponde l'isbn ha contenuti aggiuntivi
export const checkAttachments = async(isbn: string) => {
    const config: Object = {
        method: 'get',
        url: `${Constants.approURL}/get/books/checkAttachments/${isbn}`,
        headers: { 
          'Authorization': `Basic  ${btoa(Constants.approUser + ":" + Constants.approPassword)}`,
          'Content-type' : 'application/json'
        }
    };
    
    return await axios(config);
}

// prende i contenuti aggiuntivi di un volume da Approfondimenti
export const getAttachments = async(isbn : string) => {
    const config: Object = {
      method: 'get',
      url: `${Constants.approURL}/get/books/attachmentsByIsbn/${isbn}`,
      headers: { 
        'Authorization': `Basic  ${btoa(Constants.approUser + ":" + Constants.approPassword)}`,
        'Content-type' : 'application/json'
      }
    };
    
    return await axios(config)
}