import {
    Grid,
    H3,
    Modal,
} from '@maggioli-design-system/react'
import {
    SubscriptionPlanOne,
    SubscriptionPlanPro,
    SubscriptionPlanPremium,
} from '../components/SubscriptionPlan';

interface ISubscribeStep1 {
    show: boolean,
    setShow: Function,
    setShow2: Function,
}

// Modale abbonamento step 1
const SubscribeStep1 = ({ show, setShow, setShow2 }: ISubscribeStep1): JSX.Element => {

    return (
        <Modal classNameWindow="max-w-6xl" maxHeight maxWidth visible={show} onCancel={() => setShow(false)} position="center" wide={true} footer={false} contentOnly>
            <Grid className="background-color-adjust-tone-18 py-24 xxl:py-10 px-6 lg:px-24">
                <H3 className="text-center">Scegli l'abbonamento che fa per te</H3>
                <Grid className="view-limit" columns="3">
                    <SubscriptionPlanOne setShow={setShow} setShow2={setShow2} />
                    <SubscriptionPlanPro setShow={setShow} setShow2={setShow2} />
                    <SubscriptionPlanPremium setShow={setShow} setShow2={setShow2} />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default SubscribeStep1
