import React, { ReactElement, useState } from 'react'
import {
    Grid,
    Select,
} from '@maggioli-design-system/react'

interface Props {
    selectFormat: Function,
}

export default function ILibroFilter({ selectFormat }: Props): ReactElement {

    const formats: string[] = ['Tutti', 'iLibro', 'Cartaceo'];

    const [format, setFormat] = useState<string>('Tutti');

    const setSelectFormat = (value: string) => {
        setFormat(value);
        selectFormat(value);
    }

    return (
        <Grid gutter="xsmall">
            <label htmlFor="select-formato" className="-mb-2 text-base uppercase font-bold">Formato</label>
            <div className='select__item'>
                <select className="select__field text-secondary text-secondary--detail" id="select-formato" 
                        onChange={(e) => setSelectFormat(e.target.value)} value={format}>
                    { formats.map((f, i) => <option key={i} className="select__option " value={f}>{f}</option> )}
                </select>
                <i className="icon icon--size-normal select__icon mgg-icons-navigation-show"></i>
            </div>
        </Grid>
    )
}
