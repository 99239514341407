import React, { useEffect, useState } from 'react'
import {
    UrlPreview
} from '@maggioli-design-system/react'

interface IILibro {
    show: boolean,
    setShow: Function,
    isbn: string,
    title: string,
    cache?: number,
    viewer: string,
}

// Modale di visualizzazione del pdf
const ILibro = ({ show, setShow, isbn, title, cache, viewer }: IILibro): JSX.Element => {

    const [redaToken, setRedaToken] = useState<string>("");

    useEffect(()=> {
        awaitToken();
    }, [])

    const awaitToken = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REDA_URL + "/api/authenticate", {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                method: "POST",
                body: JSON.stringify({
                    username: process.env.REACT_APP_REDA_USER,
                    password: process.env.REACT_APP_REDA_PASS,
                }),
            });

            const content = await response.json();
            setRedaToken(content.id_token);
        } catch (e) {
            console.log(e);
        }
    };

    const viewerLink: string = viewer === "" ? `https://e-book.maggiolicloud.it/v2/x/${isbn}?token=${redaToken}&cache=${cache}` : viewer;

    return (
        show &&
            <UrlPreview
                className="z-20"
                visible={show}
                onCancel={() => setShow(false)}
                wide="true"
                centered={true}
                size={"fit-window"}
                title={title}
                url={viewerLink}/>
    )
}

export default ILibro
