import axios from "axios";
import { privacyURL } from "../lib/Constants";

export const getForm = async (): Promise<any> => {
    const config: Object = {
        method: "post",
        url: `${privacyURL}/get/form`,
        data: {
            token: process.env.REACT_APP_PRIVACY_TOKEN,
        },
    };
    return await axios(config);
};
