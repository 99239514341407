import React, { useEffect, Fragment, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Grid, Row, H4, Icon, H1, H2, Image } from "@maggioli-design-system/react";

import AreasGrid from "../components/AreasGrid";
import VideoTutorial from "../components/VideoTutorial";
import ExampleBook from "../components/ExampleBook";
import { getCookie, setCookie } from "../lib/Constants";
import { checkIP, enableIP, introspect } from "../lib/Functions";
import { Keycloak } from "keycloak-auth";
import Singleton from "../lib/Singleton";
import { IProdotto } from "../Interfaces/IProdotto";

interface Props {
    searchText: string;
    setShowSearch: Function;
    ip: string;
    prodotti: IProdotto[];
}

const Home = ({ searchText, setShowSearch, ip, prodotti }: Props): JSX.Element => {
    const history = useNavigate();
    const idProdotti: string[] = prodotti.map((p) => p.id.toString());

    const noipCheck = localStorage.getItem("noIP");
    const ipEnabled = localStorage.getItem("ipEnabled");
    const checkIPCookie = getCookie("checkIP");

    const refreshToken = getCookie("refresh_token");

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const code: string = queryParameters.get("code");
    const [showBanner, setShowBanner] = useState<boolean>(noipCheck === null && checkIPCookie !== null && refreshToken === null);

    useEffect(() => {
        login();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `Biblioteca digitale Maggioli`;
        setShowSearch(true);

        return () => setShowSearch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (searchText.length > 2) {
            history("/categoria/lm-amministrazione/208/1");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const refreshTokens = async (code: string, refresh: boolean = false) => {
        const dataKey = await Keycloak.auth().getToken(code, refresh);
        if (!dataKey.access_token) {
            return false;
        }

        return dataKey.access_token;
    };

    const login = async () => {
        if (!code && refreshToken !== null) {
            return;
        }
        if (!code && refreshToken === null) {
            checkLoginSSO();
            return;
        }
        const accessToken: string = Singleton.getActiveToken();
        if (!accessToken) {
            return;
        }
        const data: string = refreshToken !== null ? refreshToken : code;
        const refreshedToken = await refreshTokens(data, refreshToken !== null);
        if (!refreshedToken) {
            return;
        }
        const decodedToken = await introspect(refreshedToken);
        if (!decodedToken) {
            return;
        }
        if (ipEnabled === null && checkIPCookie !== null) {
            const res = await enableIP(ip, accessToken, decodedToken.preferred_username, decodedToken.sub, idProdotti);
            if (!res) {
                return;
            }
            localStorage.setItem("ipEnabled", "ipEnabled");
        }
        window.location.href = "/";
    };

    const checkLoginSSO = async () => {
        if (noipCheck !== null || checkIPCookie !== null) {
            return;
        }

        const guestToken = Singleton.getActiveToken();
        if (!guestToken) {
            return;
        }
        const check = await checkIP(ip, guestToken, idProdotti);
        if (check === null) {
            return;
        }
        if (!check) {
            localStorage.setItem("noip", "noip");
            return;
        }

        setCookie("checkIP", "checkIP", 1800);
        setShowBanner(true);
    };

    const onClickHideBanner = () => {
        setShowBanner(false);
    };

    const onClickRegisterLogin = () => {
        window.location.replace(Keycloak.auth().createLoginString());
    };

    return (
        <Fragment>
            {showBanner && (
                <div className="bg-status-warning-18 p-10">
                    <div className="view-limit">
                        <Row lastChild="to-right">
                            <H4>
                                Per continuare ad accedere ai contenuti del sito registrati con il tuo indirizzo email di lavoro. Successivamente
                                potrai accedere con le credenziali in possesso. Se sei già registrato clicca qui sotto su Accedi.
                            </H4>
                            <Icon name="crud-cancel" className="cursor-pointer" onClick={() => onClickHideBanner()} />
                        </Row>
                        <Button className="mt-3" onClick={() => onClickRegisterLogin()}>
                            Registrati / Accedi
                        </Button>
                    </div>
                </div>
            )}
            <div className="p-4 text-status-success-20 bg-gradient-to-t from-status-success-07 to-status-success-03">
                <Grid className="view-limit" columns="2">
                    {/* Immagine libro home */}
                    <Image alt="immagine home" className="my-8 lg:my-32" src={`${process.env.PUBLIC_URL}/assets/images/digital-book.svg`} />

                    {/* Descrizione principale */}
                    <Grid align="center" className="lg:p-8">
                        <Grid>
                            <div>
                                <H1>
                                    Benvenuto nella <br />
                                    Biblioteca Digitale
                                </H1>
                                <H2 className="text-lg">Oltre 50.000 pagine sempre disponibili su PC, Tablet e Smartphone</H2>
                            </div>
                            <div>
                                <Link to="/servizio" className="block">
                                    <Button icon="document-book" iconPosition="right" variant="secondary">
                                        Scopri il servizio
                                    </Button>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            {/* Griglia aree tematiche */}
            <div className="px-8 py-16 bg-adjust-tone-19">
                <div className="view-limit mb-16">
                    <H2 className="text-center vertical-padding-large mb-10">Le aree tematiche di Biblioteca Digitale</H2>
                    <AreasGrid prodotti={prodotti} />
                </div>
                <H2 className="text-center mb-6">Scopri le funzionalità</H2>
                <div className="grid gap-4 grid-cols-2 max-w-5xl m-auto">
                    <VideoTutorial />
                    <ExampleBook />
                </div>
            </div>
        </Fragment>
    );
};

export default Home;
