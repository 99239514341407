import {useEffect} from 'react'
import {
    Grid,
    H4,
    H3,
    Detail,
    Caption,
} from '@maggioli-design-system/react'
import ContactForm from '../components/ContactForm';
import { ILoader } from '../Interfaces/ILoader';

interface IContact {
    ip: string,
    loader: ILoader
}

const Contact = ({ ip, loader }: IContact): JSX.Element => {

    useEffect(() => {
        document.title = `Contatti | Biblioteca Digitale`
    }, [])

    return (
        <div className="padding-normal background-color-adjust-tone-19">

            {/* Titolo */}
            <H3 className="text-center vertical-padding-xxsmall">Contatti</H3>

            <Grid className="view-limit lg:px-8" columns="2" gutter="xlarge" rows="fit-vertically">

                <Grid>
                    <H4>Richiesta informazioni</H4>
                    
                    {/* Form contatti */}
                    <ContactForm ip={ip} footerMessage='' loader={loader} mailSubject="Biblioteca Digitale - Richiesta informazioni"
                            requestType='contact'/> 
                </Grid>
                <div className="pt-16 border-t-2 border-adjust-tone-15 lg:border-none">

                    {/* Info di contatto */}
                    <Grid gutter="xsmall">
                        <Detail><strong>Servizio clienti</strong></Detail>
                        <Caption>
                            Disponibile dal Lunedì al Venerdì<br />
                            Dalle 08:30 alle 17:30
                        </Caption>
                        <Caption>Tel. +39 0541 628200</Caption>
                    </Grid>
					<Grid gutter="xsmall" className="py-16">
						<Detail><strong>Maggioli Editore</strong></Detail>
						<Caption>
                            Via del Carpino, 8<br />47822 Santarcangelo di Romagna (RN) Italia
                        </Caption>
					</Grid>
				</div>
            </Grid>
        </div>
    )
}

export default Contact
