export default class Singleton {
    static token = null;
    static active_token = null;

    static getInstance(token: null | string = null) {
        if (Singleton.token === null) {
            Singleton.token = token !== null ? token : Singleton.token;
        }
        return this.token;
    }

    static getActiveToken(token: null | string = null) {
        if (Singleton.active_token === null) {
            Singleton.active_token = token !== null ? token : Singleton.active_token;
        }
        return this.active_token;
    }
}

export class SingletonProducts {
    static data = [];

    static getInstance(data: IProdottoAttivo[] | null = null): IProdottoAttivo[] | null {
        if (SingletonProducts.data === null) {
            SingletonProducts.data = data !== null ? data : SingletonProducts.data;
        }
        return this.data;
    }
}

export interface IProdottoAttivo {
    id_prodotto: string;
    scadenza: string;
}
