import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, Caption, Detail, Grid, H3, Modal } from "@maggioli-design-system/react";

import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import * as BookService from "../services/Books";
import * as AttachService from "../services/Attachments";
import * as Months from "../lib/Months";
import { IBook } from "../Interfaces/IBook";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import BookCover from "../components/BookCover";
import { BookLabel } from "../components/Book";

interface IBookPage {
    setShowModal: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

const BookPage = ({ setShowModal, loader, idProdotti, redazionale }: IBookPage): JSX.Element => {
    const { slug } = useParams();
    const history = useNavigate();

    const [show, setShow] = useState<boolean>(false);
    const [modalVisibile, setModalVisibile] = useState<boolean>(false);

    const [showIndex, setShowIndex] = useState<boolean>(false);
    const [book, setBook] = useState<IBook>(null);
    const [index, setIndex] = useState<string>("");
    const [hasAttach, setHasAttach] = useState<boolean>(false);

    useEffect(() => {
        const isbn: string = slug.split("-").pop();
        uploadTitle();

        loader.setLoader(true);
        BookService.getRedaBook(isbn).then((result) => {
            if (!result.status) {
                return;
            }
            setBook(result.data);
            AttachService.checkAttachments(isbn).then((result) => {
                if (!result.status) {
                    return;
                }
                setHasAttach(result.data.data[0].has_contents);
                loader.setLoader(false);
            });
        });

        BookService.getIndex(isbn, (res) => {
            if (res.outline) {
                setIndex(res.outline);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadTitle = () => {
        const path: string[] = slug.split("-");
        path.pop();
        document.title = `${path.join(" ")} | Biblioteca digitale Maggioli`;
    };

    const openIlibro = () => {
        if (!book.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setShow(true);
        }
    };

    const onClickAttachments = () => {
        if (!book.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setModalVisibile(true);
        }
    };

    const hasIlibro = (): boolean => {
        return book !== null && book.formati !== undefined && book.formati.includes("iLibro");
    };

    const bookTitle = (): string => {
        const title = book !== null ? book.name : "";
        return hasIlibro() ? `iLibro - ${title}` : title;
    };

    const btnIcon: string = hasIlibro() ? "logo-ilibro" : "";

    const description: string = book !== null && book.description !== undefined ? book.description.replace(/<strong>/gi, "<br/><strong>") : "";

    const isNew = book !== null ? book.mese >= new Date().getMonth() && book.anno === new Date().getFullYear() : "";
    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            {book !== null && (
                <div className="view-limit pt-8 pb-0 lg:pb-8">
                    <Grid template="book" gutter="xlarge">
                        <div className="pb-10 border-b-2 border-adjust-tone-17 lg:pb-5 lg:border-0">
                            <Grid className="sticky top-32">
                                <div>
                                    <Button icon="action-back" variant="primary-outline" onClick={() => history(-1)} horizontalPadding="small">
                                        Torna indietro
                                    </Button>
                                </div>
                                {/* Copertina del volume */}
                                {/* <BookCover url={`${expressURL}/api/books/view-cover/${book.isbn}`} /> */}
                                <div>
                                    <BookCover isbn={book.isbn} bookPage />
                                    {isNew && <BookLabel className="w-full z-10 " />}
                                </div>
                                {/* ISBN */}
                                <Grid className="gap-0">
                                    <Detail><strong>ISBN</strong></Detail>
                                    <Caption>{book.isbn}</Caption>
                                </Grid>

                                {/* Numero di pagine (se c'è) */}
                                {book.numberOfPages > 0 && (
                                    <Grid className="gap-0">
                                        <Detail><strong>Pagine</strong></Detail>
                                        <Caption>{book.numberOfPages}</Caption>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                        <Grid rows="fit-vertically">
                            {/* Titolo volume */}
                            <H3>{book.name}</H3>

                            {/* Descrizione del volume */}
                            <Grid className="gap-4">
                                <Detail>
                                    <span dangerouslySetInnerHTML={{ __html: description }} />
                                </Detail>
                            </Grid>
                        </Grid>

                        <div className="pb-10 lg:pb-5">
                            <Grid className="sticky top-32 gap-8">
                                <Grid className="gap-4">
                                    {/* Link scopri il servizio (visibile solo se non loggati) */}
                                    {!book.hasAccessToExtraContent && (
                                        <Link to="/servizio">
                                            <Button width="fill" icon="document-book" variant="secondary-outline">
                                                Scopri il servizio
                                            </Button>
                                        </Link>
                                    )}

                                    {/* Bottone di visualizzazione dell'indice del volume */}
                                    {index !== "" ? (
                                        <Button icon="data-view" variant="info" onClick={() => setShowIndex(true)}>
                                            Vedi l'indice
                                        </Button>
                                    ) : null}

                                    {/* Bottone di consultazione del volume (iLibro se disponibile) */}
                                    <Button icon={btnIcon} variant="primary" onClick={() => openIlibro()}>
                                        Consulta il volume
                                    </Button>

                                    {/* Bottone contenuti aggiuntivi */}
                                    {hasAttach && (
                                        <Button icon="additional-contents" variant="secondary" onClick={() => onClickAttachments()}>
                                            Contenuti aggiuntivi
                                        </Button>
                                    )}
                                </Grid>
                                <Grid className="gap-4">
                                    {/* Autori */}
                                    {book.autori !== undefined && (
                                        <Grid className="gap-0">
                                            <Detail><strong>Autori</strong></Detail>
                                            {book.autori.map((elem, index) => (
                                                <Caption key={index}>{elem}</Caption>
                                            ))}
                                        </Grid>
                                    )}

                                    {/* Anno e mese di pubblicazione */}
                                    <Grid className="gap-0">
                                        <Detail><strong>Pubblicazione</strong></Detail>
                                        <Caption>{`${Months.getName(book.mese)} ${book.anno}`}</Caption>
                                    </Grid>

                                    {/* Collana volume */}
                                    <Grid className="gap-0">
                                        <Detail><strong>Collana</strong></Detail>
                                        <Caption>{book.collana}</Caption>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Modal visible={showIndex} onCancel={() => setShowIndex(false)} footer={false}>
                        <H3 className="mb-3">Indice</H3>
                        <Detail
                            className="cursor-pointer"
                            onClick={() => {
                                setShowIndex(false);
                                openIlibro();
                            }}
                        >
                            <p dangerouslySetInnerHTML={{ __html: index }}></p>
                        </Detail>
                    </Modal>

                    {/* Modale consultazione volume */}
                    <ILibro show={show} setShow={setShow} isbn={book.isbn} title={bookTitle()} cache={Date.now()} viewer={book.viewer} />

                    {/* Modale contenuti aggiuntivi */}
                    <Attachments
                        redazionale={redazionale}
                        loader={loader}
                        idProdotti={idProdotti}
                        key={book ? book.isbn : 0}
                        book={book}
                        visible={modalVisibile}
                        hideModal={() => setModalVisibile(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default BookPage;
