import React from 'react'

export default function VideoTutorial() {
  return (
    <div className="flex justify-center">
        <iframe src="https://www.youtube-nocookie.com/embed/eR4Xsm8pK1g" title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen className="box-border video-tutorial max-w-[600px]" width="100%" height="100%"/>
    </div>
  )
}