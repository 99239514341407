import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Banner, Grid, H5, Tag } from "@maggioli-design-system/react";

import * as AttachService from "../services/Attachments";
import * as BookService from "../services/Books";
import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import { ILibroCard, FilterYear } from "../components/Sidebar";
import { scrollTop, replaceMenuName, serverResponseOK, replaceAreaName } from "../lib/Functions";
import { IBook } from "../Interfaces/IBook";
import { IYear } from "../Interfaces/IYear";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import RenderBooks from "../components/RenderBooks";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import ILibroFilter from "../components/ILibroFilter";

interface IHasAttach {
    isbn: string;
    has_contents: boolean;
}

interface ICategory {
    setShowModal: Function;
    searchText: string;
    setShowSearch: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

interface IModal {
    visible: boolean;
    book: IBook;
}

const Category = ({ setShowModal, searchText, setShowSearch, loader, idProdotti, redazionale }: ICategory): JSX.Element => {
    const { slug, page, id }: any = useParams();
    const history = useNavigate();

    const [modal, setModal] = useState<IModal>({ visible: false, book: null });
    const [show, setShow] = useState<boolean>(false);
    const [isbn, setIsbn] = useState<string>("");

    const [allBooks, setAllBooks] = useState<IBook[]>([]);
    const [books, setBooks] = useState<IBook[]>([]);
    const [checkAttach, setCheckAttach] = useState<IHasAttach[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>("Tutti");
    const [format, setFormat] = useState<string>("Tutti");

    const [endResult, setEndResult] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [writing, setWriting] = useState<boolean>(false);

    let timeID = useRef<any>(null);
    let time = useRef<number>(0);

    const upSound = new Audio("/assets/sounds/up.mp3");
    const downSound = new Audio("/assets/sounds/down.mp3");

    const CATEGORY_NAME: string = replaceMenuName(slug.replaceAll("-", " "));
    const AREE_NAME: string = replaceAreaName(slug.replaceAll("-", " "));
    const TIME_SEARCH: number = 500;
    const MIN_LENGTH_SEARCH: number = 3;

    useEffect(() => {
        updateTitle();
        setShowSearch(true);
        getYears();

        return () => setShowSearch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateTitle();

        return () => updateTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CATEGORY_NAME]);

    useEffect(() => {
        setBooks([]);
        getBooks();
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, slug, format]);

    useEffect(() => {
        getBooks(page - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }
        if (!writing) {
            easterSound();
            if (searchText.length >= MIN_LENGTH_SEARCH) {
                searchBooks();
            } else if (searchText.length === 0) {
                getBooks();
            }
        }
        return () => setFirstLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writing]);

    useEffect(() => {
        if (firstLoad) {
            return;
        }
        time.current = 0;
        setWriting(true);

        if (timeID.current === null) {
            timeID.current = setInterval(function () {
                if (time.current < TIME_SEARCH) {
                    time.current = time.current + TIME_SEARCH;
                } else {
                    time.current = 0;
                    setWriting(false);
                    clearInterval(timeID.current);
                    timeID.current = null;
                }
            }, TIME_SEARCH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const scrollDown = () => {
        const y = sessionStorage.getItem("scrollPage");
        if (y !== null && parseInt(y) > 0) {
            window.scrollTo({
                top: parseInt(y),
                behavior: "smooth",
            });
            sessionStorage.removeItem("scrollPage");
        }
    };

    // Aggiorna il titolo della pagina
    const updateTitle = () => {
        document.title = `${CATEGORY_NAME} | Biblioteca digitale Maggioli`;
    };

    // Salva i libri nella variabile e setta gli altri parametri
    const loadBooks = (books: IBook[], page: number) => {
        // BETA cerca libri
        if (page === 0 || (!endResult && books.length > 0)) {
            setBooks((oldArray) => (page > 0 ? [...oldArray, ...books] : books));
            setEndResult(false);
            searchText.length < MIN_LENGTH_SEARCH && setAllBooks(books);
        }
        if (books.length < 10) {
            setEndResult(true);
        }

        scrollDown();
    };

    // Controlla se ci sono contenuti aggiuntivi su Approfondimenti per i volumi passati
    const checkAttachments = (data: IBook[], page: number) => {
        AttachService.checkAttachments(data.map((elem) => elem.isbn).toString())
            .then((result) => {
                if (serverResponseOK(result)) {
                    setCheckAttach((oldArray) => (page > 0 ? [...oldArray, ...result.data.data] : result.data.data));
                }
            })
            .catch((e) => console.log(e));
    };

    // Restituisce gli anni di pubblicazione dei libri dal Redazionale
    const getYears = () => {
        BookService.getRedaBookYears().then((result) => {
            if (serverResponseOK(result)) {
                setYears(result.data.map((elem: IYear) => elem.key));
            }
        });
    };

    // Prende i libri dal redazionale, a seconda della pagina, area e anno
    const getBooks = (newPage = 0) => {
        const size: number = books.length === 0 && page > 1 ? page * 10 : 10;
        loader.setLoader(true);
        BookService.getRedaBooks(newPage, selectedYear, AREE_NAME, format, size)
            .then((result) => {
                loader.setLoader(false);
                if (serverResponseOK(result)) {
                    result.data.length > 0 && checkAttachments(result.data, newPage);
                    loadBooks(result.data, newPage);
                }
            })
            .catch((e) => console.log(e));
    };

    // Cerca i libri sul redazionale
    const searchBooks = (newPage = 0) => {
        loader.setLoader(true);
        if (newPage === 0) {
            scrollTop();
        }

        BookService.searchRedaBooks(searchText, newPage)
            .then((result) => {
                if (serverResponseOK(result) && result.data.length > 0) {
                    checkAttachments(result.data, newPage);
                }
                loadBooks(result.data, newPage);
                loader.setLoader(false);
            })
            .catch((e) => console.log(e));
    };

    // Titolo del volume da mostrare nella modale dell'iLibro
    const bookTitle = (): string => {
        const book: IBook = books.filter((elem) => elem.isbn === isbn)[0];
        return book === undefined ? "" : book.formati !== undefined && book.formati.includes("iLibro") ? `iLibro - ${book.name}` : book.name;
    };

    const easterSound = () => {
        searchText === "frank matano" && upSound.play();
        searchText === "malgioglio" && downSound.play();
    };

    // Errore da mostrare nei casi in cui non ci siano volumi
    const errorText: string =
        searchText.length >= 3
            ? `Non sono stati trovati volumi per la ricerca effettuata.`
            : "Non ci sono volumi relativi all'anno e la categoria selezionati.";
    const viewer: string = books.filter((elem) => elem.isbn === isbn).length === 1 ? books.filter((elem) => elem.isbn === isbn)[0].viewer : "";

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid template="category" gutter="xlarge">
                    <div className="hidden lg:block">
                        <Grid className="sticky top-32">
                            {/* filtro per anni */}
                            {<FilterYear data={years} selectYear={(v) => setSelectedYear(v)} />}

                            {/* Filtro per formato */}
                            {<ILibroFilter selectFormat={(v: string) => setFormat(v)} />}

                            {/* Sidebar */}
                            <ILibroCard className="lg:hidden xxl:grid" />
                        </Grid>
                    </div>
                    <Grid rows="fit-vertically">
                        {/* Banner errore no libri da mostrare */}
                        {books.length === 0 && (
                            <Grid rows="fit-vertically">
                                <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                    {errorText}
                                </Banner>
                            </Grid>
                        )}

                        <Grid columns="2" gutter="xlarge" className="search-results" rows="fit-vertically">
                            {/* Lista libri */}
                            {books.length > 0 && (
                                <RenderBooks
                                    data={books}
                                    setShow={setShow}
                                    setShowModal={setShowModal}
                                    setIsbn={setIsbn}
                                    hasAttach={checkAttach.map((elem) => elem.has_contents)}
                                    setAttachModal={setModal}
                                    idProdotti={idProdotti}
                                />
                            )}

                            {/* Lista libri quando una ricerca non produce risultati */}
                            {books.length === 0 && searchText.length > MIN_LENGTH_SEARCH && (
                                <RenderBooks
                                    data={allBooks}
                                    setShow={setShow}
                                    setShowModal={setShowModal}
                                    setIsbn={setIsbn}
                                    hasAttach={checkAttach.map((elem) => elem.has_contents)}
                                    setAttachModal={setModal}
                                    idProdotti={idProdotti}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <div className="text-center -mx-58 lg:mx-0 py-0 pt-8 lg:py-24">
                    {!endResult ? (
                        // bottone per caricare più risultati
                        <Tag
                            chip
                            className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15"
                            onClick={() =>
                                searchText.length < MIN_LENGTH_SEARCH
                                    ? history(`/categoria/${slug}/${id}/${parseInt(page) + 1}`)
                                    : searchBooks(page + 1)
                            }
                        >
                            <H5>Carica più risultati...</H5>
                        </Tag>
                    ) : (
                        // bottone per caricare più risultati
                        <Tag
                            chip
                            className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15"
                            onClick={() => scrollTop()}
                        >
                            <H5>Torna su</H5>
                        </Tag>
                    )}
                </div>

                {/* Sidebar */}
                <ILibroCard className="lg:grid xxl:hidden my-8" />

                {/* Modale iLibro */}
                <ILibro show={show} setShow={setShow} isbn={isbn} title={bookTitle()} cache={Date.now()} viewer={viewer} />

                {/* Modale allegati */}
                <Attachments
                    redazionale={redazionale}
                    loader={loader}
                    key={modal.book ? modal.book.isbn : 0}
                    book={modal.book}
                    visible={modal.visible}
                    hideModal={() => setModal((p) => ({ ...p, visible: false }))}
                />
            </div>
        </div>
    );
};

export default Category;
