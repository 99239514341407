import {useEffect} from 'react'
import {
    Grid,
    H3,
    Button,
} from '@maggioli-design-system/react'
import { Link } from 'react-router-dom'
import * as Constants from '../lib/Constants';

interface IThankYou {
    loader: {
        visible: boolean,
        setLoader: Function,
    },
}

const ThankYou = ({ loader }: IThankYou): JSX.Element => {

    const urlParams = new URLSearchParams(window.location.search);
    const gtmParam: string = urlParams.get('gtm') === null ? '' : urlParams.get('gtm');

    useEffect(() => {
        document.title = `Grazie | Biblioteca Digitale`;
    }, [])

    const onClickGoToHome = () => {
        loader.setLoader(true);
        window.setTimeout(() => loader.setLoader(false), 1000);
    }

    return (
        <div className={`padding-normal bg-adjust-tone-19 ${gtmParam}`}>
            <H3 className="text-center vertical-padding-xxsmall">Grazie</H3>
            <Grid className="view-limit lg:px-8" columns="1" gutter="normal" rows="fit-vertically">
                {urlParams.get('gtm') !== null ? <p dangerouslySetInnerHTML={{ __html: Constants.tyText(urlParams.get('gtm')) }}/> : 'Grazie per averci contattato!'}
                <Link to="/">
                    <Button onClick={() => onClickGoToHome()} icon="paginator-first">
                        Torna alla home
                    </Button>
                </Link>
            </Grid>
            <div style={{ marginBottom: '10.45%'}} ></div>
        </div>
    )
}

export default ThankYou;
