import { useEffect, Fragment } from "react";
import { Button, Detail, Grid, H2, H3, UList, UListItem } from "@maggioli-design-system/react";
import AreasGrid from "../components/AreasGrid";
import VideoTutorial from "../components/VideoTutorial";
import ExampleBook from "../components/ExampleBook";
import { IProdotto } from "../Interfaces/IProdotto";

interface IService {
    setShowModal: Function;
    prodotti: IProdotto[];
}

const Service = ({ setShowModal, prodotti }: IService): JSX.Element => {
    useEffect(() => {
        document.title = `Servizi | Biblioteca digitale Maggioli`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const howToSubscribe = (
        <div className="pb-4">
            <Grid className="view-limit p-8 lg:grid-cols-3">
                <Detail className="lg:col-span-2">
                    È possibile abbonarsi ad una o più aree tematiche oppure scegliere l'abbonamento <b>PREMIUM</b> che prevede l'accesso a tutte le
                    aree di Biblioteca Digitale.
                </Detail>
                <div>
                    <Button className="" variant="success" onClick={() => setShowModal(true)}>
                        Come abbonarsi
                    </Button>
                </div>
            </Grid>
        </div>
    );

    return (
        <Fragment>
            <div className="px-8 background-color-adjust-tone-20">
                <div className="view-limit pt-8 pb-0 lg:pb-8">
                    <H2 className="text-center vertical-padding-normal">
                        Biblioteca Digitale è il servizio in abbonamento che consente la consultazione dei volumi Maggioli Editore
                    </H2>
                    <div className="grid gap-4 grid-cols-2 max-w-5xl m-auto">
                        <VideoTutorial />
                        <ExampleBook />
                    </div>
                    <div className="background-color-adjust-tone border-b-2 border-adjust-tone-18 pb-10">
                        <Grid className="p-8">
                            <H3 className="text-">Funzionalità</H3>
                            <UList className="pl-0 text-secondary text-secondary--detail lg:grid-cols-2 gap-y-4">
                                <UListItem icon="document-book" iconSize="normal">
                                    Visualizzatore che replica fedelmente il volume cartaceo
                                </UListItem>
                                <UListItem icon="data-view" iconSize="normal">
                                    Consultazione rapida dei contenuti tramite indice navigabile
                                </UListItem>
                                <UListItem icon="data-search" iconSize="normal">
                                    Motore di ricerca per parola e/o concetto all’interno del testo
                                </UListItem>
                                <UListItem icon="document-email" iconSize="normal">
                                    Sistema di notifiche per segnalazioni e novità
                                </UListItem>
                                <UListItem icon="logo-ilibro" iconSize="normal">
                                    Ove attiva la versione iLibro, collegamento ipertestuale a tutta la documentazione (normativa,prassi,
                                    giurisprudenza) citata nel volume, costantemente aggiornata e in versione multivigenza
                                </UListItem>
                                <UListItem icon="additional-contents" iconSize="normal">
                                    Ove presenti, consultazione di tutti i contenuti aggiuntivi online collegati ai volumi (schemi di atti,
                                    modulistica, flow-chart, video, ecc.)
                                </UListItem>
                            </UList>
                        </Grid>
                        {howToSubscribe}
                    </div>

                    <div className="pt-8 pb-8 lg:py-16">
                        <H3 className=" pb-6">Aree tematiche</H3>
                        <AreasGrid prodotti={prodotti} />
                    </div>
                    {howToSubscribe}
                </div>
            </div>
        </Fragment>
    );
};

export default Service;
