import axios from "axios";
import { expressURL } from "../lib/Constants";

// prende gli ID prodotti
export const getIdProdotti = async (token: string): Promise<any> => {
    const config: Object = {
        method: "GET",
        url: `${expressURL}/api/books/categories`,
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    };

    return await axios(config);
};
