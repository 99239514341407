import { useState } from "react";
import HeaderBiblioteca from "./components/Layout/Header";
import FooterBiblioteca from "./components/Layout/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Category from "./views/Category";
import Favorites from "./views/Favorites";
import Service from "./views/Service";
import Contact from "./views/Contact";
import BookPage from "./views/BookPage";
import ThankYou from "./views/ThankYou";
import CookiePolicy from "./views/CookiePolicy";
import Conditions from "./views/Conditions";
import { ILoader } from "./Interfaces/ILoader";
import { IRedaHandler } from "./Interfaces/IRedaHandler";
import AccountPage from "./views/AccountPage";
import { IProdotto } from "./Interfaces/IProdotto";
import Subscribe from "./views/Subscribe";
import ErrorPage from "./views/ErrorPage";
export interface ITreeObject {
    id?: number;
    voce?: string;
}

interface IApp {
    idProdotti: IProdotto[];
    ip: string;
}

const App = ({ ip, idProdotti }: IApp) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModal2, setShowModal2] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const [searchText, setSearchText] = useState("");
    const [showSearch, setShowSearch] = useState(false);

    const [match, setMatch] = useState({ url: "", params: {} });

    /**
     * start edit from redazionale
     */
    const [idBancadati, setIdBancadati] = useState<string | number>();
    const [tree, setTree] = useState<ITreeObject>();
    const [redaShow, setRedaShow] = useState<boolean>(false);
    const [isbn, setIsbn] = useState<number>();

    const handlerText = {
        searchText: searchText,
        showSearch: showSearch,
        setSearchText: setSearchText,
    };

    const handlerMatch = {
        match: match,
        setMatch: setMatch,
    };

    const handleLoader: ILoader = {
        visible: loader,
        setLoader: setLoader,
    };

    const handleReda: IRedaHandler = {
        tree: tree,
        setTree: setTree,
        redaShow: redaShow,
        setRedaShow: setRedaShow,
        idBancadati: idBancadati,
        setIdBancadati: setIdBancadati,
        isbn: isbn,
        setIsbn: setIsbn,
    };

    return (
        <div className="background-color-adjust-tone">
            <HeaderBiblioteca
                loader={handleLoader}
                handlerText={handlerText}
                handlerMatch={handlerMatch}
                showModal={showModal}
                showModal2={showModal2}
                ip={ip}
                idProdotti={idProdotti}
                setShowModal={setShowModal}
                setShowModal2={setShowModal2}
            />
            <Routes>
                <Route
                    path="/"
                    element={<Home searchText={searchText} setShowSearch={(value: boolean) => setShowSearch(value)} prodotti={idProdotti} ip={ip} />}
                />
                <Route
                    path="/categoria/:slug/:id/:page"
                    element={
                        <Category
                            setShowModal={setShowModal}
                            idProdotti={idProdotti}
                            loader={handleLoader}
                            searchText={searchText}
                            setShowSearch={(value: boolean) => setShowSearch(value)}
                            redazionale={handleReda}
                        />
                    }
                />

                <Route
                    path="/dettaglio/:slug"
                    element={<BookPage setShowModal={setShowModal} loader={handleLoader} idProdotti={idProdotti} redazionale={handleReda} />}
                />
                <Route
                    path="/favorites"
                    element={<Favorites setShowModal={setShowModal} loader={handleLoader} idProdotti={idProdotti} redazionale={handleReda} />}
                />
                <Route path="/servizio" element={<Service setShowModal={setShowModal} prodotti={idProdotti} />} />
                <Route path="/contatti" element={<Contact ip={ip} loader={handleLoader} />} />
                <Route path="/informativa-sui-cookies" element={<CookiePolicy />} />
                <Route path="/condizioni-di-servizio" element={<Conditions />} />
                <Route path="/abbonati" element={<Subscribe loader={handleLoader} ip={ip} />} />
                <Route path="/thank-you" element={<ThankYou loader={handleLoader} />} />

                <Route path="/account" element={<AccountPage idProdotti={idProdotti} ip={ip} setShowModal={setShowModal} />} />
                {/* Qualsiasi route va inserita prima del NotFound */}
                <Route element={<ErrorPage errorNum={404} title={"Pagina non trovata"} />}/>
            </Routes>
            <FooterBiblioteca loader={handleLoader} setMatch={setMatch} redazionale={handleReda} />
        </div>
    );
};

export default App;
