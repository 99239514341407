import { useEffect, useState } from "react";
import { Grid, Banner } from "@maggioli-design-system/react";
import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import { scrollTop } from "../lib/Functions";
import { ILibroCard, FilterYear } from "../components/Sidebar";
import { IBook } from "../Interfaces/IBook";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import RenderBooks from "../components/RenderBooks";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import ILibroFilter from "../components/ILibroFilter";

interface IModal {
    visible: boolean;
    book: IBook;
}

interface IWhishlist {
    id: IBook;
    hasAttach: boolean;
}

interface IFavorites {
    setShowModal: Function;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

const Favorites = ({ setShowModal, loader, idProdotti, redazionale }: IFavorites): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);
    const [isbn, setIsbn] = useState<string>("");
    const [years, setYears] = useState<string[]>([]);
    const [books, setBooks] = useState<IWhishlist[]>(JSON.parse(localStorage.getItem("whishlist")));
    const [checkAttach, setCheckAttach] = useState<boolean[]>([]);
    const [modal, setModal] = useState<IModal>({ visible: false, book: null });
    const [selectedYear, setSelectedYear] = useState<string>("Tutti");
    const [format, setFormat] = useState<string>("Tutti");

    const ERROR_TEXT: string = "Non hai ancora aggiunto volumi ai preferiti, per farlo cerca prima il volume che ti interessa e aggiungilo.";

    useEffect(() => {
        document.title = `I tuoi preferiti | Biblioteca digitale Maggioli`;
        if (books !== null) {
            updateYears(books);
            setCheckAttach(books.map((elem) => elem.hasAttach));
        }
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateBooks = () => {
        const data: IWhishlist[] = JSON.parse(localStorage.getItem("whishlist"));
        setBooks(data);
        updateYears(data);
        setCheckAttach(data.map((elem) => elem.hasAttach));
    };

    const updateYears = (newBooks: IWhishlist[]) => {
        let data = newBooks.map((elem) => elem.id.anno).sort((a, b) => b - a);
        data = data.filter((elem, index) => index === 0 || elem !== data[index - 1]);
        if (data.length > 1) {
            setYears(data.map((elem) => elem.toString()));
        }
    };

    const getBooks = (): IWhishlist[] => {
        const data: IWhishlist[] = selectedYear === "Tutti" ? books : books.filter((elem) => elem.id.anno.toString() === selectedYear);
        return format === "iLibro"
            ? data.filter((elem) => elem.id.formati.includes("iLibro"))
            : format === "Cartaceo"
            ? data.filter((elem) => !elem.id.formati.includes("iLibro"))
            : data;
    };

    const bookTitle = (): string => {
        const book: IBook = books !== null ? books.map((elem) => elem.id).filter((elem) => elem.isbn === isbn)[0] : undefined;
        return book === undefined || book.formati === undefined ? "" : book.formati.includes("iLibro") ? `iLibro - ${book.name}` : book.name;
    };

    const bookViewer = (): string => {
        const book: IBook = books !== null ? books.map((elem) => elem.id).filter((elem) => elem.isbn === isbn)[0] : undefined;
        return book === undefined ? "" : book.viewer;
    };

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid template="category" gutter="xlarge">
                    <div className="hidden lg:block">
                        <Grid className="sticky top-32">
                            {/* Select per filtrare i libri per anno di pubblicazione */}
                            <FilterYear data={years} selectYear={(year: string) => setSelectedYear(year)} />

                            {/* Filtro per formato */}
                            {books !== null && books.length > 0 && <ILibroFilter selectFormat={(v: string) => setFormat(v)} />}

                            {/* Sidebar */}
                            <ILibroCard className="lg:hidden xxl:grid" />
                        </Grid>
                    </div>

                    {/* Lista dei volumi */}
                    <Grid columns={books !== null && books.length > 0 ? 2 : 1} gutter="xlarge" className="search-results" rows="fit-vertically">
                        {books !== null && books.length > 0 ? (
                            <RenderBooks
                                hasAttach={checkAttach}
                                update={() => updateBooks()}
                                setShow={setShow}
                                data={books !== null ? getBooks().map((elem) => elem.id) : []}
                                setShowModal={setShowModal}
                                setAttachModal={setModal}
                                setIsbn={setIsbn}
                                idProdotti={idProdotti}
                            />
                        ) : (
                            <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                {ERROR_TEXT}
                            </Banner>
                        )}
                    </Grid>
                </Grid>

                {/* Sidebar */}
                <ILibroCard className="lg:grid xxl:hidden my-8" />

                {/* Modale iLibro */}
                <ILibro show={show} setShow={setShow} isbn={isbn} title={bookTitle()} cache={Date.now()} viewer={bookViewer()} />

                {/* Modale contenuti aggiuntivi */}
                <Attachments
                    redazionale={redazionale}
                    loader={loader}
                    book={modal.book}
                    visible={modal.visible}
                    hideModal={() => setModal((p) => ({ ...p, visible: false }))}
                />
            </div>
        </div>
    );
};

export default Favorites;
