import { Fragment } from "react";
import { Button } from "@maggioli-design-system/react";
import { IProdotto } from "../Interfaces/IProdotto";
import { getCookie } from "../lib/Constants";
import { Keycloak } from "keycloak-auth";
import { deleteAllCookies } from "../lib/Functions";

interface ILoginButton {
    setShowModal: Function;
    idProdotti: IProdotto[];
    ip?: string;
}

const LoginButton = ({ setShowModal }: ILoginButton): JSX.Element => {
    const islogged: boolean = getCookie("refresh_token") !== null;

    const logout = async () => {
        const refreshToken: string | null = getCookie("refresh_token");
        if (refreshToken !== null) {
            await Keycloak.auth().logout(refreshToken);
        }
        deleteAllCookies();
        window.location.href = "/";
    };

    return (
        <Fragment>
            {islogged ? (
                <Fragment>
                    <Button>I miei preferiti</Button>
                    <Button variant="secondary-outline" onClick={() => logout()}>
                        Esci
                    </Button>
                </Fragment>
            ) : (
                <Fragment>
                    <Button variant="secondary-outline" onClick={() => window.location.replace(Keycloak.auth().createLoginString())}>
                        Accedi
                    </Button>
                    <Button variant="success" onClick={() => setShowModal(true)}>
                        Come abbonarsi
                    </Button>
                </Fragment>
            )}
        </Fragment>
    );
};

export default LoginButton;
