import { Grid, H3, Modal } from "@maggioli-design-system/react";
import { ResultSelectedPlan } from "../components/SubscriptionPlan";
import ContactForm from "../components/ContactForm";
import * as Constants from "../lib/Constants";
import { ILoader } from "../Interfaces/ILoader";

interface ISubscribeStep2 {
    show: boolean;
    setShow: Function;
    backToFirst: Function;
    ip: string;
    loader: ILoader;
}

export interface ISubInfo {
    areas: string[];
    period: string;
}

// Modale abbonamento step 2
const SubscribeStep2 = ({ show, setShow, backToFirst, ip, loader }: ISubscribeStep2): JSX.Element => {
    const subInfo: ISubInfo = JSON.parse(sessionStorage.getItem("sub"));

    return (
        <Modal
            classNameWindow="max-w-6xl"
            maxHeight
            maxWidth
            visible={show}
            onCancel={() => setShow(false)}
            position="center"
            wide={true}
            footer={false}
            contentOnly
        >
            <Grid className="background-color-adjust-tone-18 py-20 xxl:py-10 px-6 lg:px-24">
                <H3 className="text-center">Richiesta preventivo</H3>
                <Grid className="view-limit" columns="2" gutter="xlarge" rows="fit-vertically">
                    <div>
                        <ResultSelectedPlan setShow={setShow} backToFirst={backToFirst} data={subInfo} />
                    </div>
                    <ContactForm
                        ip={ip}
                        footerMessage={Constants.footerMessageSubMail(subInfo)}
                        loader={loader}
                        mailSubject="Biblioteca Digitale - Richiesta preventivo"
                        requestType="sales"
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SubscribeStep2;
