import React, { ReactElement, useEffect } from "react";
import { Button, Caption, Card, Detail, Grid, H3, H4, Icon, Row } from "@maggioli-design-system/react";

import { Link } from "react-router-dom";
import { replaceMenuName } from "../lib/Functions";
import { expressURL, getCookie } from "../lib/Constants";
import Singleton, { IProdottoAttivo } from "../lib/Singleton";
import ErrorPage from "./ErrorPage";
import { TailSpin } from "react-loader-spinner";

interface Props {
    idProdotti: any;
    ip: string;
    setShowModal: Function;
}

export default function AccountPage({ idProdotti, setShowModal }: Props): ReactElement {
    const userIsLogged: boolean = getCookie("access_token") !== null && getCookie("refresh_token") !== null;

    const [prodottiAttivi, setProdottiAttivi] = React.useState<IProdottoAttivo[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        loadProdottiAttivi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadProdottiAttivi = async () => {
        if (!userIsLogged) {
            return;
        }
        const accessToken = Singleton.getActiveToken();
        setLoading(true);
        const res = await fetch(`${expressURL}/api/users/profile`, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id_prodotti: idProdotti.map((p) => p.id).join(",") }),
        });
        setLoading(false);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        const subProducts = [];
        data.forEach((p) => {
            subProducts.push(
                ...p.sub_products.map((sp) => {
                    return {
                        ...sp,
                        scadenza: p.scadenza,
                    };
                }),
            );
        });
        setProdottiAttivi([...data, ...subProducts]);
    };
    if (!userIsLogged) {
        return <ErrorPage errorNum={401} title="Eseguire l'accesso prima di visualizzare la pagina" />;
    }

    // Converte una stringa in data
    const fromStringToDate = (date: string): string => {
        if (!date) {
            return "";
        }
        const temp: string[] = date.split(" ");
        const temp2: string[] = temp[0].split("-");
        return `${temp2.pop()}-${temp2.pop()}-${temp2.pop()}`;
    };

    const idProdottiAttivi: string[] = prodottiAttivi.map((p) => p.id_prodotto);

    const singleExpiringDate = (idProdotto: string) => {
        const prodotto = prodottiAttivi.filter((p) => p.id_prodotto === idProdotto.toString())[0];
        if (prodotto) {
            return fromStringToDate(prodotto.scadenza);
        }
        const prodotto200 = prodottiAttivi.filter((p) => p.id_prodotto === "200")[0];
        if (prodotto200) {
            return fromStringToDate(prodotto200.scadenza);
        }
        return false;
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            {/* Titolo */}
            <H3 className="text-center vertical-padding-xxsmall">Account utente</H3>

            <Grid className="view-limit lg:px-8" columns="1" gutter="small" rows="fit-vertically">
                {/* Nome utente */}
                <Detail>{/* Nome utente: <b>{userdata.name}</b>{" "} */}</Detail>

                <Row lastChild="to-right">
                    <H4>L'abbonamento è attivo per le aree evidenziate</H4>
                    <Button variant="success-outline" horizontalPadding="none" onClick={() => setShowModal(true)}>
                        Estendi abbonamento
                    </Button>
                </Row>
                {loading ? (
                    <div className="mx-auto">
                        <TailSpin color="#0041B9" />
                    </div>
                ) : (
                    <Grid columns="3">
                        {idProdotti
                            .filter((p) => p.id > 201)
                            .map((p, i) => {
                                const isActive: boolean = idProdottiAttivi.includes(p.id) || idProdottiAttivi.includes("200");
                                const href: string = `/categoria/${p.descrizione.replaceAll(" ", "-").toLowerCase()}/${p.id}/1`;
                                return (
                                    <Link key={i} to={href}>
                                        <Card padding="small" className={isActive ? "bg-status-success-19" : ""}>
                                            <Row lastChild="to-right">
                                                <Grid gutter="xxsmall">
                                                    <Detail>{replaceMenuName(p.descrizione)}</Detail>
                                                    <Caption>{singleExpiringDate(p.id) ? singleExpiringDate(p.id) : "---"}</Caption>
                                                </Grid>
                                                {isActive ? <Icon name="crud-save" /> : <div></div>}
                                            </Row>
                                        </Card>
                                    </Link>
                                );
                            })}
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
