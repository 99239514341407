import React, { ReactElement } from "react";
import { IBook } from "../Interfaces/IBook";
import Book from "../components/Book";
import { IProdotto } from "../Interfaces/IProdotto";

interface Props {
    setShow: Function;
    setShowModal: Function;
    setIsbn: Function;
    data: IBook[];
    hasAttach: boolean[];
    setAttachModal: Function;
    idProdotti?: IProdotto[];
    update?: Function;
}

// Lista di libri da inserire in griglia
export default function RenderBooks({ setShow, setShowModal, setIsbn, data, hasAttach, setAttachModal, idProdotti, update }: Props): ReactElement {
    const books = data.map((v, i) => (
        <Book
            key={i}
            setAttachModal={setAttachModal}
            setShowModal={setShowModal}
            setShow={setShow}
            setIsbn={setIsbn}
            update={update}
            actions={true}
            allFavorites={false}
            data={v}
            iLibro={v.formati !== undefined && v.formati.includes("iLibro")}
            hasAttach={hasAttach[i]}
            idProdotti={idProdotti}
        />
    ));
    return <>{books}</>;
}
